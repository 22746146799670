<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general />
    </b-tab>
    <!--/ general tab -->
    <b-tab v-if="!$ability.can('manage', 'Incubator')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="HeartIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">My Interests</span>
      </template>
      <interests />
    </b-tab>
    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Security</span>
      </template>

      <account-setting-password />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="Share2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Referral</span>
      </template>
      <refer-page />
    </b-tab>
  </b-tabs>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BTabs, BTab } from 'bootstrap-vue'

import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import Interests from './Interests.vue'
import ReferPage from './ReferPage.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    Interests,
    ReferPage,
  },
}
</script>
